"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpLink, loggerLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { signOut, useSession } from "next-auth/react";
import superjson from "superjson";

import type { AppRouter } from "@offline/app-trpc";
import { HttpHeader } from "@offline/constants/enums";

import { SELECTED_ORIGIN_LOCATION_ID_LOCAL_STORAGE_KEY } from "~/constants";
import { clearUserSessionData } from "~/helpers";
import authHandlerLink from "~/helpers/trpc/authHandlerLink";
import getBaseUrl from "~/helpers/trpc/getBaseUrl";
import { useRouter } from "~/navigation";

export const appTRPC = createTRPCReact<AppRouter>();

const queryClient = new QueryClient();

interface Props {
  children: React.ReactNode;
}

export default function TRPCProvider({ children }: Readonly<Props>) {
  const session = useSession();
  const router = useRouter();

  const trpcClient = appTRPC.createClient({
    links: [
      authHandlerLink({
        onUnauthorized: () => {
          clearUserSessionData();
          signOut();
        },
        onMissingMinimumRequiredAccessRole: () => {
          router.replace("/errors/forbidden");
        },
      }),
      loggerLink({
        enabled: () => process.env.NODE_ENV === "development",
      }),
      httpLink({
        url: `${getBaseUrl()}/api/app`,
        transformer: superjson,
        headers: () => {
          const headers: Record<string, string> = {};

          if (session.data?.user) {
            const { id, storeId, tiendanubeUserId } = session.data.user;
            headers[HttpHeader.X_STORE_ID] = storeId;
            headers[HttpHeader.X_USER_ID] = id;
            headers[HttpHeader.X_TIENDANUBE_USER_ID] = tiendanubeUserId;
          }

          const planLocationId =
            typeof window !== "undefined"
              ? localStorage.getItem(
                  SELECTED_ORIGIN_LOCATION_ID_LOCAL_STORAGE_KEY,
                )
              : null;
          if (planLocationId) {
            headers[HttpHeader.X_PLAN_LOCATION_ID] = JSON.parse(planLocationId);
          }

          return headers;
        },
      }),
    ],
  });

  return (
    <appTRPC.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </appTRPC.Provider>
  );
}
