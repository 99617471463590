import { type TRPCLink } from "@trpc/client";
import { type AnyTRPCRouter } from "@trpc/server";
import { observable, tap } from "@trpc/server/observable";

import {
  MISSING_MINIMUM_ACCESS_ROLE,
  UNAUTHORIZED,
} from "@offline/constants/errors";

// NOSONAR
export default function authHandlerLink<
  TRouter extends AnyTRPCRouter = AnyTRPCRouter,
>({
  onUnauthorized,
  onMissingMinimumRequiredAccessRole,
}: {
  onUnauthorized: () => void;
  onMissingMinimumRequiredAccessRole: () => void;
}): TRPCLink<TRouter> {
  return () => {
    return ({ op, next }) => {
      return observable((observer) => {
        return next(op)
          .pipe(
            tap({
              error({ message }) {
                if (message === MISSING_MINIMUM_ACCESS_ROLE) {
                  onMissingMinimumRequiredAccessRole();
                } else if (message === UNAUTHORIZED) {
                  onUnauthorized();
                }
              },
            }),
          )
          .subscribe(observer);
      });
    };
  };
}
